export const secondsToMinutesHours = (seconds) => {
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const sec = seconds % 60;

	// return as string
	if (hours === 0) return `${minutes}m ${sec}s`;

	if (minutes === 0) return `${sec}s`;

	return `${hours}h ${minutes % 60}m ${sec}s`;
};
